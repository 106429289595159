import React from 'react'
import styled from 'styled-components'

export const Title = ({title, subtitle, className}) => {
    return (
        <div className={className}>
            <h4>
                <span className="title">{title}</span>
                <span>{subtitle}</span>
            </h4>
        </div>
    )
}

export default styled(Title)`
    text-transform: uppercase;
    font-size: 2.3rem;
    margin-bottom: 2rem;
    h4 {
        text-align: center;
        letter-spacing: 5px;
        color: var(--primaryColor);
        font-size: inherit;
        margin: inherit;
    }
    span {
        margin-right: 15px;
        white-space: nowrap;
    }
    .title {
        color: var(--mainBlack);
    }
`