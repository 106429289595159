import React from 'react'
import Image from 'gatsby-image'
import styles from 'src/styles/project.module.css'
import { FaGithub } from 'react-icons/fa'
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Project = ({ project }) => {  
    const { fields, frontmatter, id } = project
    const slug = fields.slug
    const { description, github, mainImage, tags, title } = frontmatter
    
    return (
        <article className={styles.project}>
            <div className={styles.imgContainer}>
                <AniLink fade to={`/portfolio/${slug}`} >
                    <Image fluid={mainImage.childImageSharp.fluid} className={styles.img} alt="featured project" />
                </AniLink>
            </div>
            <div className={styles.footer}>
                <h3>{title}</h3>
                <div className={styles.info}>
                    <h4 className={styles.item}>
                        <div className={styles.tags}>
                        <a key={id} href={github} target="_blank" rel="noopener noreferrer">
                            <FaGithub className={styles.icon} />
                        </a>
                        &nbsp;
                            {tags.map( (tag, index) => { return <span key={`${id}${index}`}> {tag} &nbsp; </span> })}
                        </div>
                    </h4>
                    <div className={styles.details}>
                        <h6>{description}</h6>
                    </div>
                </div>
            </div>
        </article>
    )
}

export default Project